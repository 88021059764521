<template>
  <div>
    <validation-observer ref="createProject">
      <div v-if="step == 1">
        <h2 class="font-weight-bolder mb-2">
          <span @click="$router.go(-1)">
            <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
          </span>
          Crear la obra
        </h2>
        <b-card>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre'" label-for="project-name">
                  <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Código obra'"
                rules="required"
              >
                <b-form-group :label="'Código obra'" label-for="project-SKU">
                  <b-form-input
                    v-model="SKU"
                    name="Código obra"
                    :placeholder="'Código obra'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Estado'"
                rules="required"
              >
                <b-form-group :label="'Estado'" label-for="project-status">
                  <v-select
                    label="alias"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="'Estado'"
                    v-model="status"
                  >
                    <template slot="option" slot-scope="option">
                      {{ statusProjectsText[option.alias] }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ statusProjectsText[option.alias] }}
                    </template>
                  </v-select>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Cliente'" label-for="project-client">
                  <v-select
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectClients"
                    :placeholder="'Cliente'"
                    v-model="client"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                :label="'Descripción'"
                label-for="project-description"
              >
                <quill-editor v-model="description" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Documentos'" label-for="project-documents">
                <ImageDropzone ref="documents" :files="files" maxFiles="10" />
              </b-form-group>
              <b-row>
                <b-col sm="12" class="pb-1"> Duración de la obra </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="'Fecha de inicio'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Fecha de inicio'"
                      label-for="project-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="'Fecha de fin'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Fecha de fin'"
                      label-for="project-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                @click="nextStep(2)"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ "Continuar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-if="step == 2">
        <h2 class="font-weight-bolder mb-2">
          <span @click="step = 1">
            <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button> </span
          >Otros detalles de la obra
        </h2>
        <b-card>
          <b-row>
            <b-col sm="6">
              <div sm="12" class="pb-1">Recurso Preventivo en obra</div>
              <div class="repeater-form">
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group label="Nombre" label-for="item-name">
                      <b-form-input
                        :id="item.name"
                        v-model="item.name"
                        type="text"
                        placeholder="Nombre"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Teléfono" label-for="item-phone">
                      <b-form-input
                        :id="item.phone"
                        v-model="item.phone"
                        type="text"
                        placeholder="Teléfono"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="1" md="1" class="mb-50">
                    <span
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="d-inline-block px-50 text-danger mt-2 icon-trash"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </span>
                  </b-col>
                </b-row>
                <b-button
                  variant="outline-danger"
                  @click="repeateAgain"
                  class="mb-2"
                  :disabled="isDisabled"
                >
                  <span>Añadir</span>
                </b-button>
              </div>

              <div sm="12" class="pb-1">Coordinador de Seguridad y Salud</div>
              <div class="repeater-form">
                <b-row>
                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group label="Nombre" label-for="item-name">
                      <b-form-input
                        id="item-name"
                        type="text"
                        placeholder="Nombre"
                        v-model="person_coordinador_seguridad_salud"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Teléfono" label-for="item-name">
                      <b-form-input
                        id="item-name"
                        type="text"
                        placeholder="Teléfono"
                        v-model="phone_coordinador_seguridad_salud"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col sm="6">
              <b-row>
                <b-col sm="12" class="pb-1"> Cobertura de seguro </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de inicio'"
                    label-for="project-date_ini_seguro"
                  >
                    <flat-pickr
                      v-model="date_ini_seguro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de fin'"
                    label-for="project-date_fin_seguro"
                  >
                    <flat-pickr
                      v-model="date_fin_seguro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="pb-1"> Apertura centro de trabajo </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de inicio'"
                    label-for="project-date_ini_centro"
                  >
                    <flat-pickr
                      v-model="date_ini_centro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de fin'"
                    label-for="project-date_fin_centro"
                  >
                    <flat-pickr
                      v-model="date_fin_centro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="pt-1">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="subcontrata"
                      :value="true"
                      size="sm"
                      class="check-subcontrata"
                    >
                      Subcontrata
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                :label="'Importe facturado'"
                label-for="project-importe_facturado"
                v-if="role == 'admin' || role == 'super_admin'"
              >
                <b-input-group append="€">
                  <b-form-input
                    v-model="importe_facturado"
                    name="Importe facturado"
                    :placeholder="'0'"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group
                :label="'Importe pendiente'"
                label-for="project-importe_pendiente"
                v-if="role == 'admin' || role == 'super_admin'"
              >
                <b-input-group append="€">
                  <b-form-input
                    v-model="importe_pendiente"
                    name="Importe pendiente"
                    :placeholder="'0'"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                @click="nextStep(3)"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ "Continuar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-if="step == 3">
        <h2 class="font-weight-bolder mb-2">
          <span @click="step = 2">
            <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
          </span>
          Cargar las tareas de la obra
        </h2>
        <b-card>
          <b-row>
            <b-col sm="12">
              <b-form-group>
                <p class="font-weight-bolder">Departamentos</p>
                <b-row>
                  <b-col
                    sm="4"
                    v-if="item.active"
                    :key="item.id"
                    v-for="item in selectDepartments"
                  >
                    <b-form-checkbox
                      v-model="departments"
                      :value="item.id"
                      size="sm"
                      class="check-departamentos"
                    >
                      {{ item.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="'tareas'"
                  rules="required"
                >
                  <p class="font-weight-bolder">
                    ¿Quieres cargar todas las tareas o quieres cargar
                    manualmente una tarea especifica?
                  </p>
                  <b-form-checkbox
                    v-model="typeTasks"
                    value="all"
                    size="sm"
                    class="d-inline-block check-departamentos"
                  >
                    Cargar todas las tareas (todos los departamentos)
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="typeTasks"
                    value="manual"
                    size="sm"
                    class="d-inline-block check-departamentos ml-2"
                  >
                    Cargar manualmente (seleccionar tareas)
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="typeTasks == 'manual'">
                <div
                  v-for="(item, index) in selectCategories"
                  :key="index"
                  class="div-collapse"
                >
                  <div class="d-block p-1 header-collapse">
                    <b-form-checkbox
                      :id="`check-${hash(index)}`"
                      :value="hash(index)"
                      size="sm"
                      class="d-inline-block check-departamentos"
                      :checked="isChecked(index)"
                      @change="toggleDepartment(index, hash(index))"
                    />
                    <b-link v-b-toggle :href="`#collapse-${hash(index)}`">
                      {{ index }}
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="20"
                        class="arrow-collapse arrow-collapse_down"
                      />
                      <feather-icon
                        icon="ChevronUpIcon"
                        size="20"
                        class="arrow-collapse arrow-collapse_up"
                      />
                    </b-link>
                  </div>
                  <b-collapse :id="`collapse-${hash(index)}`" class="mt-2">
                    <b-card no-body class="card-collapse">
                      <div :key="ic" v-for="(child, ic) in item">
                        <div v-if="child.childrens" class="mb-2">
                          <p class="m-0">
                            <b-form-checkbox
                              :id="`check-child-${hash(ic)}`"
                              :value="hash(ic)"
                              size="sm"
                              class="d-inline-block check-departamentos"
                              :checked="isCheckedChild(index, ic)"
                              @change="toggleChild(index, ic, hash(ic))"
                            />
                            <strong>{{ ic }}</strong>
                          </p>
                          <div
                            v-for="i in child.childrens"
                            :key="i.id"
                            class="pl-1"
                          >
                            <b-form-checkbox
                              v-model="categories"
                              :value="i.id"
                              size="sm"
                              class="d-inline-block check-departamentos ml-2"
                            >
                              {{ i.name }}
                            </b-form-checkbox>
                          </div>
                        </div>
                        <div v-else class="mb-2">
                          <b-form-checkbox
                            :key="child.id"
                            v-model="categories"
                            :value="child.id"
                            size="sm"
                            class="d-inline-block check-departamentos"
                          >
                            {{ child.name }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
                @click="handleSubmit"
              >
                {{ "Crear obra" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BCollapse,
  VBToggle,
  BLink,
  BInputGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
    BCollapse,
    BLink,
    config,
    BInputGroup,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusProjectsText: config.statusProjectsText,
      name: "",
      SKU: "",
      description: "",
      date_ini: "",
      date_fin: "",
      date_ini_seguro: "",
      date_fin_seguro: "",
      date_ini_centro: "",
      date_fin_centro: "",
      departments: [],
      categories: [],
      files: [],
      status: "",
      client: "",
      typeTasks: "",
      required,
      email,
      step: 1,
      dataFirstStep: "",
      subcontrata: false,
      importe_facturado: "",
      importe_pendiente: "",
      items: [
        {
          id: 1,
          name: "",
          phone: "",
        },
      ],
      nextTodoId: 2,
      isDisabled: false,
      person_coordinador_seguridad_salud: "",
      phone_coordinador_seguridad_salud: "",
    };
  },
  mounted() {
    this.initTrHeight();
  },
  computed: {
    ...mapGetters({
      selectStatus: "projects/getSelectStatus",
      selectClients: "clients/getSelectClients",
      selectDepartments: "departments/getSelectDepartments",
      selectCategories: "categories/getItemsCreateProject",
      clientParam: "clients/getClient",
      role: "auth/getRole",
    }),
  },
  watch: {
    departments() {
      this.getListCategoriesCreateProject({ departments: this.departments });
    },
  },
  methods: {
    ...mapActions({
      create: "projects/create",
      getSelectStatus: "projects/selectStatus",
      getSelectDepartments: "departments/selectDepartments",
      getListCategoriesCreateProject:
        "categories/getListCategoriesCreateProject",
      getSelectClients: "clients/selectClients",
      getClient: "clients/getClient",
    }),
    repeateAgain() {
      if (this.items.length < 3) {
        this.items.push({
          id: (this.nextTodoId = this.nextTodoId + 1),
        });
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
        });
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.items.length < 3) {
        this.isDisabled = false;
      }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        //console.log(this.$refs)
        //this.trSetHeight(this.$refs.div.scrollHeight)
      });
    },
    handleSubmit() {
      this.$refs.createProject.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ project: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.dataFirstStep;
      data.append("date_ini", FlatPickrToTimestamp(this.date_ini));
      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestamp(this.date_fin));
      } else {
        data.append("date_fin", null);
      }
      if (this.date_ini_seguro) {
        data.append(
          "date_ini_seguro",
          FlatPickrToTimestamp(this.date_ini_seguro)
        );
      } else {
        data.append("date_ini_seguro", null);
      }
      if (this.date_fin_seguro) {
        data.append(
          "date_fin_seguro",
          FlatPickrToTimestamp(this.date_fin_seguro)
        );
      } else {
        data.append("date_fin_seguro", null);
      }
      if (this.date_ini_centro) {
        data.append(
          "date_ini_centro",
          FlatPickrToTimestamp(this.date_ini_centro)
        );
      } else {
        data.append("date_ini_centro", null);
      }
      if (this.date_fin_centro) {
        data.append(
          "date_fin_centro",
          FlatPickrToTimestamp(this.date_fin_centro)
        );
      } else {
        data.append("date_fin_centro", null);
      }
      if (this.importe_facturado) {
        data.append("importe_facturado", this.importe_facturado);
      } else {
        data.append("importe_facturado", null);
      }
      if (this.importe_pendiente) {
        data.append("importe_pendiente", this.importe_pendiente);
      } else {
        data.append("importe_pendiente", null);
      }
      if (this.departments.length > 0) {
        this.departments.forEach((element) => {
          data.append("departments[]", element);
        });
      } else {
        data.append("departments", "");
      }
      if (this.categories.length > 0) {
        this.categories.forEach((element) => {
          data.append("categories[]", element);
        });
      } else {
        data.append("categories", "");
      }

      if (this.items.length > 0) {
        for (let f in this.items) {
          if (this.items[f]["name"] != "") {
            data.append(
              "recurso_preventivo[]",
              JSON.stringify({
                id: this.items[f]["id"],
                name: this.items[f]["name"],
                phone: this.items[f]["phone"],
              })
            );
          }
        }
      }

      data.append("name", this.name);
      data.append("status", this.status);
      data.append("SKU", this.SKU);
      data.append("client_id", this.client.id);
      data.append("description", this.description);
      data.append("charge_tasks", this.typeTasks);
      data.append("subcontrata", this.subcontrata);
      data.append(
        "person_coordinador_seguridad_salud",
        this.person_coordinador_seguridad_salud
      );
      data.append(
        "phone_coordinador_seguridad_salud",
        this.phone_coordinador_seguridad_salud
      );

      return data;
    },
    setClient() {
      if (this.$route.params.client_param) {
        this.getClient(this.$route.params.client_param).then(() => {
          this.client = this.clientParam;
        });
      }
    },
    nextStep(num) {
      this.$refs.createProject.validate().then((success) => {
        if (success) {
          if (num === 2) {
            this.dataFirstStep = this.$refs.documents.getFormData("documents");
          }
          this.step = num;
        }
      });
    },
    hash(s) {
      let hash = 0;
      let i;
      let c;
      const strlen = s.length;
      if (strlen === 0) {
        return hash;
      }
      for (i = 0; i < strlen; i++) {
        c = s.charCodeAt(i);
        hash = (hash << 5) - hash + c;
        hash &= hash; // Convert to 32bit integer
      }
      return hash;
    },
    toggleDepartment(key, hash) {
      if (document.getElementById(`check-${hash}`).checked) {
        Object.keys(this.selectCategories[key]).forEach((e) => {
          if (this.selectCategories[key][e].childrens) {
            this.selectCategories[key][e].childrens.forEach((c) => {
              this.categories.push(c.id);
            });
          } else {
            this.categories.push(this.selectCategories[key][e].id);
          }
        });
      } else {
        Object.keys(this.selectCategories[key]).forEach((e) => {
          if (this.selectCategories[key][e].childrens) {
            this.selectCategories[key][e].childrens.forEach((c) => {
              for (let i = 0; i < this.categories.length; i++) {
                if (this.categories[i] === c.id) {
                  this.categories.splice(i, 1);
                  i--;
                }
              }
            });
          } else {
            for (let i = 0; i < this.categories.length; i++) {
              if (this.categories[i] === this.selectCategories[key][e].id) {
                this.categories.splice(i, 1);
                i--;
              }
            }
          }
        });
      }
    },
    isChecked(index) {
      let checked = true;
      Object.keys(this.selectCategories[index]).forEach((e) => {
        if (this.selectCategories[index][e].childrens) {
          this.selectCategories[index][e].childrens.forEach((c) => {
            if (!this.categories.includes(c.id)) {
              checked = false;
            }
          });
        } else if (
          !this.categories.includes(this.selectCategories[index][e].id)
        ) {
          checked = false;
        }
      });
      if (checked) {
        return this.hash(index);
      }
      return "";
    },
    isCheckedChild(index, ic) {
      let checked = true;
      this.selectCategories[index][ic].childrens.forEach((e) => {
        if (!this.categories.includes(e.id)) {
          checked = false;
        }
      });
      if (checked) {
        return this.hash(ic);
      }
      return "";
    },
    toggleChild(index, ic, hash) {
      if (document.getElementById(`check-child-${hash}`).checked) {
        this.selectCategories[index][ic].childrens.forEach((e) => {
          this.categories.push(e.id);
        });
      } else {
        this.selectCategories[index][ic].childrens.forEach((e) => {
          for (let i = 0; i < this.categories.length; i++) {
            if (this.categories[i] === e.id) {
              this.categories.splice(i, 1);
              i--;
            }
          }
        });
      }
    },
  },
  async created() {
    await this.getSelectStatus();
    await this.getSelectDepartments({});
    await this.getSelectClients();
    await this.setClient();
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.icon-trash {
  padding-top: 7px;
}
</style>
